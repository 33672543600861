import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

class Docs extends React.Component {

  DownloadLink = ({ href, children }) => {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" className="external-link">
        {children} <FontAwesomeIcon icon={solid('download')} />
      </a>
    );
  }

  render() {
    return (
      <div className="site-section bg-light" id="docs">
        <div className="container">
          <div className="row mb-3">
            <div className="col-12 text-center">
              <h2 className="section-title">Documentation</h2>
            </div>
          </div>
          <div className="row align-items-stretch" >
            <div className="col-md-12 col-lg-12 mb-12 mb-lg-12 aos-init aos-animate" data-aos="fade-up" id="docs">
              <div className="unit-4 d-block ">
                <p>     
                  Our <this.DownloadLink href="https://www.li10.com/li10-user-guide.pdf">User Guide</this.DownloadLink> 
                  &nbsp;and <this.DownloadLink href="https://www.li10.com/li10-admin-guide.pdf">Administrator Guide</this.DownloadLink> are available for download.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Docs;
