import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import monitor from "../assets/monitor.png"
import score from "../assets/score.png"
import userReport from "../assets/user-report.png"
import { faGithub } from '@fortawesome/free-brands-svg-icons';

class Solutions extends React.Component {

  resolveIcon = (icon) => {
    const icons = {
      'eye': solid('eye'),
      'scale-unbalanced': solid('scale-unbalanced'),
      'robot': solid('robot'),
      'comments': solid('comments'),
      'network-wired': solid('network-wired'),
      'rectangle-list': solid('rectangle-list')
    };
    return icons[icon] || solid('eye');
  }

  ExternalLink = ({ href, children }) => {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" className="external-link">
        {children} <FontAwesomeIcon icon={solid('external-link-alt')} />
      </a>
    );
  }

  SolutionHeader = ({ icon, title }) => {
    const resolvedIcon = this.resolveIcon(icon);

    return (
      <div className="unit-4-icon mb-3">
        <span className="icon-wrap">
          <span className="text-primary">
            <FontAwesomeIcon icon={resolvedIcon} />
          </span>
        </span>
        <h2>{title}</h2>
      </div>
    );
  }

  render() {
    return (
      <div className="site-section bg-light" id="solutions">
        <div className="container">
          <div className="row mb-5">
            <div className="col-12 text-center">
              <h2 className="section-title mb-3">Solutions</h2>
            </div>
          </div>
          <div className="row align-items-stretch" >
            <div className="col-md-12 col-lg-12 mb-12 mb-lg-12 aos-init aos-animate" data-aos="fade-up" id="co2-monitor">
              <div className="unit-4 d-block solution-box-height">

                <this.SolutionHeader icon="eye" title="Li10 CO2 Monitor" />

                <p>
                  The <b>Li10 CO2 Monitor</b> measures the sustainability impact of your cloud environment and provides a dashboard to analyze it.
                </p>

                <div
                  className="row"
                  style={{ display: "flex", gap: "10px" }}
                >
                  <div className="col-lg-4">
                    <img
                      src={monitor} title="Measure and Analyze" alt="Measure and Analyze"
                      className="img-thumbnail"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                      }} />
                  </div>
                  <div
                    className="col-lg-6"
                    style={{ display: "flex" }}
                  >
                    <p>
                      Our dashboard gives you a clear understanding of your cloud carbon footprint.
                      <br />
                      You will be able see:
                      <li>your CO2 emissions</li>
                      <li>your electricity consumption in kwh</li>
                      <li>the associated cloud cost</li>
                      <li>your <b>Li10 Cloud Sustainability Score</b></li>
                    </p>
                  </div>
                </div>

                <div>
                  <p>
                    To calculate your cloud emissions, we use:
                    <li><this.ExternalLink href="https://docs.aws.amazon.com/cur/latest/userguide/what-is-cur.html">AWS data</this.ExternalLink> from your Billing Account</li>
                    <li>the Software Carbon Intensity methodology <br />defined by the <this.ExternalLink href="https://greensoftware.foundation/">Green Software Foundation</this.ExternalLink></li>
                    <li>a library from the <this.ExternalLink href="https://www.cloudcarbonfootprint.org/">Cloud Carbon Footprint</this.ExternalLink> project</li>
                  </p>
                  <p>
                    The <b>Li10 CO2 Monitor</b> calculates a <b>Cloud Sustainability Score</b> to easily understand of your cloud environment's sustainability posture. This score acts as a benchmark to establish sustainability objectives and foster ongoing enhancements.
                  </p>
                  <div className="col-lg-4 m-2" >
                    <img src={score} title="Cloud Sustainability Score" alt="Cloud Sustainability Score" className="img-thumbnail"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                      }} />
                  </div>
                </div>

                <div>
                  <b className="h4">How is our approach different?</b><br />
                  <ul>
                    <li>we use the open, unbias and cloud agnostic methodology from the GSF</li>
                    <li>as a SaaS, there is no software to install and manage</li>
                    <li>our dashboard runs as a modern serverless application which generates less emissions than a dedicated container or instance-based infrastructure</li>
                  </ul>
                </div>

                <div style={{ bottom: "4%", width: "90%", textAlign: "center" }}>
                  <a href="https://app.li10.com" className="btn btn-primary"><b>Register to monitor your emissions</b></a>
                </div>

              </div>
            </div>

            <div className="col-md-12 col-lg-12 mb-12 mb-lg-12 aos-init aos-animate" data-aos="fade-up" id="li10-usage-report">
              <div className="unit-4 d-block solution-box-height">

                <this.SolutionHeader icon="rectangle-list" title="Li10 Usage Report" />

                <p className="mb-5"><b>Li10 Usage Report</b> is our SaaS service bringing transparency and accountability to cloud users. The individual reports generated allow each user to verify which resources should be retained, eliminated or optimized.
                </p>

                <div className="m-2" >
                    <img src={userReport} title="Cloud Sustainability Score" alt="Cloud Sustainability Score" className="img-thumbnail"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                      }} />
                  </div>

                <div style={{ bottom: "4%", width: "90%", textAlign: "center" }}>
                  <a href="https://app.li10.com" className="btn btn-primary"><b>Register to elimate cloud waste</b></a>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-12 mb-12 mb-lg-12 aos-init aos-animate" data-aos="fade-up" id="li10-governance">
              <div className="unit-4 d-block solution-box-height">

                <this.SolutionHeader icon="robot" title="Li10 Governance" />

                <p className="mb-5"><b>Li10 Governance</b> is our <this.ExternalLink href="https://github.com/li10labs/li10-governance">open source solution <FontAwesomeIcon icon={faGithub} /></this.ExternalLink> based on Cloud Custodian. It automates non-compliance detection and remediation in your cloud environment. The solution consists of field-tested policies-as-code, infrastructure-as-code and pipelines which can be used by DevOps engineers to effectively maintain and enhance their organization's cloud governance posture. This solution can be used along with AWS Config and AWS Security Hub.
                </p>
              </div>
            </div>

            <div className="col-md-12 col-lg-12 mb-12 mb-lg-12 aos-init aos-animate" data-aos="fade-up" id="li10-network-switch">
              <div className="unit-4 d-block solution-box-height">

                <this.SolutionHeader icon="network-wired" title="Li10 Network Switch" />

                <p className="mb-5"><b>Li10 Network Switch</b> is our <this.ExternalLink href="https://github.com/li10labs/li10-network-switch">open source solution <FontAwesomeIcon icon={faGithub} /></this.ExternalLink>  to reduce the cost and environmental impact of AWS NAT Gateways for ephemeral workloads.
                </p>
              </div>
            </div>

            <div className="col-md-12 col-lg-12 mb-12 mb-lg-12 aos-init aos-animate" data-aos="fade-up" id="consulting-services">
              <div className="unit-4 d-block solution-box-height">
                <this.SolutionHeader icon="comments" title="Consulting Services" />

                <p>
                  Our team of senior cloud engineers has experience architecting and automating cloud solutions for startups
                  and large Fortune 500 enterprises alike. We worked with the manufacturing, ecommerce, finance, hospitality and healthcare industries.
                </p>
                <p>You can use our expertise assess, design and implement solutions tailored to your needs.</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default Solutions;
