import React from "react";
import logo from "../assets/li10-logo-light-bg.svg"

class Header extends React.Component {
    render() {
      return (
        <div className="container">
        <div className="row">
          <div className="col-12 text-center mb-4 mt-4 d-block">
            <div className="site-logo">
              <a href="/">
              <img src={logo} alt="li10"/>
                </a>
              </div>
          </div>
        </div>
      </div>
      );
    }
}

export default Header;
