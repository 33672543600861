import React from "react"
import about from "../assets/about.jpg"

// Photo by <a href="https://unsplash.com/@shanerounce?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Shane Rounce</a> on <a href="https://unsplash.com/photos/a-group-of-people-holding-hands-on-top-of-a-tree-DNkoNXQti3c?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
      

function About() {
  return (
    <div className="site-section bg-light" id="about">
      <div className="container">
        <div className="row mb-3">
          <div className="col-12 text-center">
            <h2 className="section-title">About Us</h2>
          </div>
        </div>
        <div className="row  aos-init aos-animate unit-4" data-aos="fade-up">
          <div className="d-none d-lg-block col-lg-5">
            <img src={about} title="About Us" alt="About Us" className="img-fluid mb-5 mb-lg-0 rounded shadow"></img>
          </div>
          <div className="col-lg-5 ml-auto pl-lg-5 ">
            <p className="mb-4">At Li10, we are dedicated to making a positive impact on our planet by addressing the growing concern of carbon emissions generated by cloud computing. Our mission is to provide sustainable and eco-friendly solutions to businesses worldwide, helping them reduce their carbon footprint and contribute to a greener future.</p>
            <p className="mb-4">Founded in 2022, our team of passionate technologists and environmentalists recognized the need for a more responsible approach to cloud computing. We understand that as technology evolves and businesses increasingly rely on cloud services, it is our collective responsibility to ensure the sustainability of our digital infrastructure.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;
